.hoverable-space {
  position: relative; /* This will help in positioning the icon. */
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  border: 1px solid #ddd;
}

.hoverable-space:hover {
  background-color: #f1efe2 !important;
  border: 1px solid #e5b04f;
}
.hoverable-space .hover-icon {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverable-space:hover .hover-icon {
  opacity: 1;
}
